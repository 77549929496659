/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useLayoutEffect } from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'elements/Hero/HeroDefault'
import Breadcrumb from 'elements/Breadcrumb'

// Components
import SEO from 'shared/SEO'
import Layout from 'layout/Layout'
import GravityForm from 'shared/GravityForm'
import ParseContent from 'shared/ParseContent'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

const FormWrapper = styled(motion.div)`
  position: relative;
  background-color: ${(props) => props.theme.color.face.main};
  padding: 30px 40px 20px 40px;
  top: -240px;

  @media screen and (max-width: 991px) {
    top: unset;
    padding: 20px 20px 20px 20px;
  }

  h2 {
    font-size: ${(props) => props.theme.font.size.lm};
    font-weight: ${(props) => props.theme.font.weight.xl};
    color: ${(props) => props.theme.color.text.light};
  }
`

const Content = styled(ParseContent)``

const StyledIframe = styled.iframe``

const PageTemplate = ({
  data: {
    page: { title, seo, paginaContact },
  },
  pageContext,
}) => {
  const [ref, isVisible] = useInView({
    threshold: 0.2,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <Layout>
      <SEO seo={seo} />

      <HeroDefault fields={paginaContact.paginacontactbanner} />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <div className="container py-lg-5">
        <div className="row py-lg-0 py-4">
          <div className="col-lg-6 ps-lg-5">
            <Content
              className="ps-lg-5 pb-lg-5 pb-3"
              content={paginaContact.paginacontactinfo.description}
            />
            <StyledIframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1223.6701948962543!2d5.427125721699077!3d52.16450439811594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c64665712b04db%3A0x38618f74e1a3ebe0!2sWiekenweg%2030%2C%203815%20KL%20Amersfoort!5e0!3m2!1snl!2snl!4v1706266398607!5m2!1snl!2snl"
              width="100%"
              height="300"
              frameborder="0"
              style={{ border: 'none' }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            />
          </div>
          <div className="col-lg-6 pt-lg-0 pt-4">
            <FormWrapper
              ref={ref}
              initial={{ y: 80 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.5, type: 'spring', damping: 20 }}
            >
              <h2 className="mb-4 pb-3">Stuur ons een bericht</h2>
              <GravityForm id={1} />
            </FormWrapper>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wpPage(databaseId: { eq: $wordpress_id }) {
      ...ContactPageFrag
    }
  }
`

export default PageTemplate
